import { motion } from "framer-motion"
import { Fragment } from "react"
import { Asset } from "../../components/Asset"
import { CompareFlows } from "../../components/CompareFlows"
import { Main } from "../../components/Main"
import { Section } from "../../components/Section"
import { SmarterKnowledge } from "./SmarterKnowledge"
import { SectionSpacer, Spacer } from "../../components/Spacer"
import { Title4 } from "../../components/Text"
import { greaterThan, lessThan } from "../../utils/breakpoints"
import { getGradient } from "../ProductPage"
import { Collaborative } from "./Collaborative"
import { FrequentlyAskedQuestions } from "../../components/FrequentlyAskedQuestions"
import { HeroTitle } from "./HeroTitle"
import { SocialProof } from "./SocialProof"
import { Management } from "./Management"
import { css } from "@shiftx/styles"
import { GotoUsecases } from "../../components/GotoUsecases"
import { AiForm } from "../../components/AiForm"

export const HighlightSection = ({
    title,
    children,
    asset,
    color,
    isOdd = false,
}) => {
    return (
        <div
            css={css`
                margin-top: var(--section-margin);
                overflow-x: hidden;
            `}
        >
            <Section
                css={css`
                    display: grid;
                    grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
                    align-items: center;
                    grid-gap: var(--grid-gap);
                `}
            >
                <div
                    css={css`
                        flex: 1;
                    `}
                >
                    {title && <Title4>{title}</Title4>}

                    {children}
                </div>

                <div
                    css={css`
                        ${isOdd &&
                        greaterThan(0)`
                            grid-column-start: 1;
                            grid-row: 1;
                            display: flex;
                            justify-content: flex-end;
                            min-width: 0;
                            flex-direction: column;
                            align-items: flex-end;
                        `}
                    `}
                >
                    <div
                        css={css`
                            width: 800px;
                            ${getGradient(color)}
                            overflow: hidden;
                            padding: 80px;
                            border-radius: 16px;

                            ${lessThan(0)`width: auto; padding: 24px;`}
                        `}
                    >
                        {asset?.[0] && <Asset asset={asset[0]} />}
                    </div>
                </div>
            </Section>
        </div>
    )
}

export const HomePage = ({ content }) => (
    <Fragment>
        <div
            css={css`
                background-color: #f5f5f5;
                padding-top: 80px;
                ${lessThan(1)`padding-top:16px`}
            `}
        >
            <HeroTitle content={content} />
        </div>

        <Main
            css={css`
                margin: 0;
                ${lessThan(1)`
                        margin: 0;
                    `}
            `}
        >
            <SectionSpacer />
            <SocialProof content={content} />
            <SectionSpacer />
            <SmarterKnowledge content={content.smarter} />
            <SectionSpacer />
            <Collaborative content={content.collaborate} />
            <SectionSpacer />
            <Management content={content.management} />
            <SectionSpacer />
            <GotoUsecases content={content.usecases} />

            {/* <SectionSpacer />
            <FrequentlyAskedQuestions
                items={[
                    {
                        question: "How do I get started?",
                        answer: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text.",
                    },
                    {
                        question: "What is included with the Free plan?",
                        answer: "How do we extend this to transition both the font color and the margin, but with the same duration and timing function? Even if properties share certain values, they can't be combined in the transition shorthand. Instead, write out each property declaration in full and comma separate each one:",
                    },
                    {
                        question: "Can I change the team size?",
                        answer: "The enterprise violated a multitude of robocall prohibitions by making pre-recorded voice calls to mobile phones without prior express consent, placing telemarketing calls without written consent, dialing numbers included on the National Do Not Call Registry, failing to identify the caller at the start of the message, and failing to provide a call-back number that allowed consumers to opt out of future calls.  The calls also violated spoofing laws by using misleading caller ID to disguise the enterprise’s role and prompt consumers to answer. We take seriously our responsibility to protect consumers and the integrity of U.S. communications networks from the onslaught of these types of pernicious calls,” said FCC Enforcement Bureau Chief Loyaan A. Egal.  “I want to thank the Enforcement Bureau’s Telecommunications Consumers Division for its groundbreaking work on this case, and we will continue to work with our federal and state partners to hold these entities and others engaged in similar conduct accountable.",
                    },
                    {
                        question: "How is security handled?",
                        answer: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text.",
                    },
                ]}
            /> */}
        </Main>
    </Fragment>
)
